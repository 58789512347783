<template>
    <div class="pt-6 h-[500px] overflow-y-scroll flex flex-col">
        <template v-if="editingFormIndex !== null || editingTime">
            <div class="flex px-4 border-b pb-4">
                <div class="w-10">
                    <PhIcon v-if="isIos" @click="exitForm" data-cy="selection-list-exit-form" icon="caret-left" weight="regular" :size="18" />
                    <PhIcon v-else @click="exitForm" data-cy="selection-list-exit-form" icon="arrow-left" weight="regular" :size="18" />
                </div>
                <div class="mx-auto" data-cy="selection-list-form-title">{{ editingFormIndex !== null ? actions[editingFormIndex].title : 'Edit time' }}</div>
                <div class="w-10 text-ux-link font-medium text-sm" @click="saveForm">Save</div>
            </div>
            <div class="flex p-4">
                <DField :label="timeField.title" id="time">
                    <DInput type="time" v-model="time" />
                </DField>
            </div>
        </template>
        <template v-else-if="editingNoteIndex !== null || noteOnly">
            <div class="flex px-4 border-b pb-4">
                <div class="w-10">
                    <template v-if="!noteOnly">
                        <PhIcon v-if="isIos" @click="exitEdit" icon="caret-left" weight="regular" :size="18" />
                        <PhIcon v-else @click="exitEdit" icon="arrow-left" weight="regular" :size="18" />
                    </template>
                </div>
                <div class="mx-auto">{{ editNoteTitle }}</div>
                <div class="w-10 text-ux-link font-medium text-sm" @click="saveEdit">Save</div>
            </div>
            <div class="text-gray-900 font-medium flex p-4">
                {{ item ? item.title : '' }}
                <div @click="deleteEdit()" class="flex text-red-700 ml-auto text-sm" v-if="editedText.length">
                    <PhIcon
                        icon="trash"
                        weight="regular"
                        :size="18"
                        class="mr-1 ml-2"
                    />
                    Delete note
                </div>
            </div>
            <DInput
                v-model="editedText"
                tag="textarea"
                placeholder="Add a note..."
                theme="transparent"
                :rows="4"
                ref="commentInput"
            />
        </template>
        <template v-else>
            <IonContent ref="contentScroll" class="flex-1">
                <template v-if="item">
                    <div class="border mx-4 py-2 px-4 bg-gray-100 rounded">
                        <div class="text-gray-900 font-medium">
                            {{ item.title }}
                        </div>
                        <div class="text-gray-600 text-sm flex items-center">
                            {{ item.status_text }}
                            <UIIcon
                                v-for="icon in item.icons"
                                :key="`item-${item.id}-icon-${icon}`"
                                class="text-primary-700 ml-2"
                                :icon="icon"
                                weight="bold"
                                :size="16"
                            />
                        </div>
                    </div>
                    <div
                        v-if="item?.time_field"
                        class="px-4 py-2 text-sm"
                        :class="{ 'border-b': shouldAddBorder(-1) }">
                        <div class="font-medium py-2 ">
                            {{ item.time_field.title }}
                        </div>
                        <div class="flex pb-2 items-center justify-between">
                            <div class="text-neutral-base text-gray-600 py-2 ">
                                {{ formattedTime }}
                            </div>
                            <div v-if="item.time_field.editable" class="flex text-black ml-auto text-sm" @click="editTime()">
                                <PhIcon
                                    icon="pencil-simple"
                                    weight="regular"
                                    :size="18"
                                    class="mr-1 ml-2"
                                />
                                Edit
                            </div>
                        </div>
                    </div>
                    <template v-for="(note, index) in notes" :key="index">
                        <div v-if="note.note || isEditable(note)" class="pb-4 mt-2" :class="{ 'border-b': shouldAddBorder(index) }">
                            <template v-if="note.note">
                                <div class="font-medium px-4 py-2 text-sm">
                                    {{ note.title }}
                                </div>
                                <div class="flex items-start px-4 py-2 text-sm text-gray-600">
                                    <div class="text-neutral-base">
                                        {{ note.note }}
                                    </div>
                                </div>
                                <div class="flex items-center px-2 mt-3">
                                    <div v-if="isEditable(note)" @click="deleteNote(index)" class="flex text-red-700 ml-2 text-sm">
                                        <PhIcon
                                            icon="trash"
                                            weight="regular"
                                            :size="18"
                                            class="mr-1"
                                        />
                                        Delete
                                    </div>
                                    <div v-if="isEditable(note)" @click="editNote(index)" class="flex text-black ml-auto text-sm mr-2">
                                        <PhIcon
                                            icon="pencil-simple"
                                            weight="regular"
                                            :size="18"
                                            class="mr-1"
                                        />
                                        Edit
                                    </div>
                                </div>
                            </template>
                            <div v-else-if="isEditable(note)" @click="addNote(index)" class="flex text-black ml-2 mt-4 text-sm items-center">
                                <PhIcon
                                    icon="plus"
                                    weight="bold"
                                    :size="15"
                                    class="mr-2 ml-2"
                                />
                                Add {{ noteLowerTitle(note) }}
                            </div>
                        </div>
                    </template>
                </template>
                <template v-if="!item?.disabled">
                    <div
                        class="font-medium px-4 py-2 transition-colors duration-150"
                        :class="{
                            'text-lg': !item,
                            'text-sm mt-4 pt-2': item,
                        }"
                    >
                        {{ title }}
                    </div>
                    <div
                        v-for="(action, index) in actions"
                        :key="action.value"
                        class="px-4 my-4 flex items-center"
                        @click="actionClick(index)"
                    >
                        <UIIcon
                            :icon="action.icon"
                            weight="bold"
                            :size="16"
                            class="mr-2"
                        />
                        {{ action.title }}
                    </div>
                </template>
            </IonContent>
        </template>
    </div>
</template>
<script setup>
    import { DInput, DField } from '@digistorm/spark'
    import { isEmpty } from 'lodash'
    import UIIcon from '@/components/ui/UIIcon.vue'
    import PhIcon from '@/components/app/PhIcon.vue'
    import dayjs from '@/dayjs'

    const props = defineProps({
        title: String,
        actions: Array,
        item: Object,
        noteOnly: Boolean,
        onAction: Function,
        onNote: Function,
        onNotes: Function,
        onContentSize: Function,
        onForm: Function,
    })

    const deviceStore = useDeviceStore()

    const { isIos } = storeToRefs(deviceStore)

    const notes = ref(props.item?.notes || [])
    const editedText = ref('')
    const editingNoteIndex = ref(null)
    const editingFormIndex = ref(null)
    const editingTime = ref(false)
    const addingNewNote = ref(props.noteOnly)
    const time = ref(props.item?.time_field?.value || '')

    const noteLowerTitle = (note) => {
        if (!note) {
            return 'Note'
        }
        return (note.title || '').toLowerCase()
    }

    const editNoteTitle = computed(() => {
        const noteTitle = noteLowerTitle(notes.value?.[editingNoteIndex.value])
        return (addingNewNote.value ? 'Add ' : 'Edit ') + noteTitle
    })

    const timeField = computed(() => {
        if (editingFormIndex.value !== null) {
            return props.actions[editingFormIndex.value].time_field
        }
        return props.item?.time_field
    })

    const formattedTime = computed(() => {
        return dayjs(`2025-01-01 ${time.value}`).format('h:mm A')
    })

    const isEditable = (note) => {
        return !props.item?.disabled && note.editable
    }

    const shouldAddBorder = (index) => {
        // If there are more notes after this one, add a border
        if (notes.value.slice(index + 1).some((note) => note.note || isEditable(note))) {
            return true
        }
        // If this is the last note, only add a border if the status is editable below
        return !props.item?.disabled
    }

    const addNote = (index) => {
        editingNoteIndex.value = index
        addingNewNote.value = true
        editedText.value = notes.value[index].note || ''
    }

    const editNote = (index) => {
        if (props.item?.disabled) {
            return
        }
        editingNoteIndex.value = index
        addingNewNote.value = false
        editedText.value = notes.value[index].note || ''
    }

    const exitEdit = () => {
        editingNoteIndex.value = null
    }

    const deleteEdit = () => {
        editedText.value = ''
    }

    const saveEdit = () => {
        if (props.noteOnly) {
            props.onNote(editedText.value)
        }
        notes.value[editingNoteIndex.value].note = editedText.value
        editingNoteIndex.value = null
    }

    const deleteNote = (index) => {
        notes.value[index].note = ''
        editedText.value = ''
    }

    const actionClick = (index) => {
        const action = props.actions[index]
        if (!isEmpty(action.time_field)) {
            // Show the time field
            editingFormIndex.value = index
        } else {
            props.onAction(action)
        }
    }

    const editTime = () => {
        editingTime.value = true
    }

    const exitForm = () => {
        editingFormIndex.value = null
        editingTime.value = false
    }

    const saveForm = () => {
        if (editingTime.value) {
            editingTime.value = false
            props.onForm({ [timeField.value.key]: time.value })
        } else {
            // Saving a status with required field
            const action = props.actions[editingFormIndex.value]
            props.onAction(action, { [timeField.value.key]: time.value })
        }
    }

    watch(() => notes.value, (newVal) => {
        props.onNotes(newVal)
    }, { deep: true })

    watch(editingNoteIndex, (newVal) => {
        props.onContentSize?.(newVal !== null)
    })
    watch(editingFormIndex, (newVal) => {
        props.onContentSize?.(newVal !== null)
    })
    watch(editingTime, (newVal) => {
        props.onContentSize?.(newVal)
    })
</script>
